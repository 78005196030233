import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopNavbar from "./TopNavbar";
import SideNavbar from "./SideNavbar";
import ConfirmModal from "./Modals/ConfirmModal";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBBadge
} from "mdb-react-ui-kit";
import { api } from "../services";

function Items() {
  const [viewMode, setViewMode] = useState("list");
  const [itemsData, setItemsData] = useState([]); // Fetched or Saved Items
  const [savedItems, setSavedItems] = useState([]); // Backup Items (Saved in DB)
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState("Select App");
  const [selectedAppId, setSelectedAppId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState("");

  const [showConfirm, setShowConfirm] = useState(false);
  const [modalData, setModalData] = useState({ title: "", message: "", action: () => { }, actionData: null, visibility: null });


  console.log(showConfirm);

  const navigate = useNavigate();

  useEffect(() => {
    fetchApps();
    fetchItems();
  }, []);

  useEffect(() => {
    if (selectedAppId) {
      fetchItems(selectedAppId);
    }
  }, [selectedAppId]);

  // Fetch Apps
  const fetchApps = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Unauthorized: Please log in.");
        navigate("/login");
        return;
      }

      const response = await api.get("/apps", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setApps(response.data.apps || []);
    } catch (error) {
      console.error(
        "Error fetching apps:",
        error.response?.data || error.message
      );
      setError("Failed to fetch apps.");
    }
  };

  // Fetch Catalog Items (Try API First, If Fails Use Saved Data)
  const fetchItems = async (appId = null) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Unauthorized: Please log in.");
        navigate("/login");
        return;
      }

      let endpoint = appId ? `/apps/${appId}/catalog/items` : `/catalog/items`;

      const response = await api.get(endpoint, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setItemsData(response.data.items || []);
      setSavedItems(response.data.items || []); // ✅ Save Fetched Data in Backup
    } catch (error) {
      console.error(
        "Error fetching catalog items:",
        error.response?.data || error.message
      );
      setError("Failed to fetch catalog items. Showing saved items.");

      // ✅ If Fetch Fails, Use Last Saved Items
      if (savedItems.length > 0) {
        setItemsData(savedItems);
      }

      // ✅ If Token is Expired, Redirect to Login
      if (error.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };

  // Handle App Selection
  const handleAppSelect = (app) => {
    setSelectedApp(app.application);
    setSelectedAppId(app._id);
    fetchItems(app._id);
  };

  // Handle Search Input Change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle View Mode Change
  const handleViewModeChange = (mode) => setViewMode(mode);

  // Handle Add Item Click
  const handleAddItemClick = () => navigate("/add-product");

  // Handle Edit Item Click
  const handleEditItemClick = (item) => {
    navigate(`/edit-product/${item.item_id}`);
  };

  

  //Handle visibility of the Product
  const handleVisibility = async (item, visibility) => {
    console.log("Confirmed!", item);
    const payload = {
      productId: item._id,
      isVisible: visibility
    };

    try {
      const token = localStorage.getItem("token");
      console.log("🔄 Sending Update visibility Payload:", payload);

      //  Correct API URL
      const response = await api.post(`hide-product`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log(" Item visibility updated Successfully:", response.data);
      
      await fetchItems();
    
    } catch (error) {
      console.error(" Error adding category:", error.response?.data || error.message);
      setError(error.response?.data?.message || "Failed to add category.");
    }

    setShowConfirm(false); // Hide modal after confirming
  };

  // Filter Items for Display
  const filteredItems = itemsData.filter((item) =>
    item.name?.en.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <TopNavbar />
      <div className="d-flex dashboard-layout">
        <SideNavbar />
        <MDBContainer fluid className="p-4 content-area flex-grow-1">
          <h2 className="mb-4">Catalog Items</h2>

          {/* Display Error Message */}
          {error && <p className="text-danger">{error}</p>}

          {/* App Selection */}
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <label>Select App</label>
              <MDBDropdown>
                <MDBDropdownToggle color="light">
                  {selectedApp}
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  {apps.map((app) => (
                    <MDBDropdownItem
                      key={app._id}
                      onClick={() => handleAppSelect(app)}
                    >
                      {app.application}
                    </MDBDropdownItem>
                  ))}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBCol>
          </MDBRow>

          {/* Quick Actions */}
          <MDBRow className="mb-4">
            <h6>Quick Actions</h6>
            <MDBCol>
              <MDBBtn
                color="primary"
                className="quick-action-btn"
                onClick={handleAddItemClick}
              >
                Add New Item
              </MDBBtn>
            </MDBCol>
          </MDBRow>

          {/* Search and View Toggle */}
          <MDBRow className="mb-4 d-flex align-items-center">
            <MDBCol md="6">
              <MDBInput
                type="text"
                placeholder="Search Items"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </MDBCol>
            <MDBCol
              md="6"
              className="d-flex justify-content-end align-items-center"
            >
              <div className="ms-3">
                <MDBBtn
                  color="light"
                  onClick={() => handleViewModeChange("list")}
                  active={viewMode === "list"}
                >
                  List
                </MDBBtn>
                <MDBBtn
                  color="light"
                  onClick={() => handleViewModeChange("grid")}
                  active={viewMode === "grid"}
                >
                  Grid
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>

          {/* Items Content */}
          {viewMode === "grid" ? (
            <MDBRow>
              {filteredItems.map((item) => (
                <MDBCol md="4" key={item.item_id}>
                  <MDBCard className="order-card h-100">
                    <MDBCardBody className="position-relative text-center">
                      <img
                        src={
                          item.image_path || "https://via.placeholder.com/150"
                        }
                        alt={item.name?.en}
                        className="img-fluid mb-3"
                        style={{ maxHeight: "150px", objectFit: "cover" }}
                      />
                      <h5 className="mb-1">{item.name?.en || "No Name"}</h5>
                      <small className="text-muted">
                        Price: SAR {item.price?.toFixed(2) || "0.00"}
                      </small>
                      <div className="text-center my-3">
                        <h6>Category: {item.category_id || "No Category"}</h6>
                      </div>
                      <MDBBtn
                        color="secondary"
                        outline
                        size="sm"
                        style={{ marginRight: "8px" }}
                        onClick={() => handleEditItemClick(item)}
                      >
                        Edit
                      </MDBBtn>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              ))}
            </MDBRow>
          ) : (
            <MDBTable align="middle" responsive>
              <MDBTableHead className="table-header">
                <tr>
                  <th class="custom-th">#</th>
                  <th class="custom-th">Item Image</th>
                  <th class="custom-th">Item Name</th>
                  <th class="custom-th">Category</th>
                  <th class="custom-th">Price</th>
                  <th class="custom-th">Visibility</th>
                  <th class="custom-th">Actions</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {filteredItems.map((item, index) => (
                  <tr key={item.item_id}>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        src={
                          item.image_path || "https://via.placeholder.com/100"
                        }
                        alt={item.name?.en}
                        className="img-fluid"
                        style={{ maxHeight: "50px", objectFit: "cover" }}
                      />
                    </td>
                    <td>{item.name?.en || "No Name"}</td>
                    <td>{item.category_id || "No Category"}</td>
                    <td>SAR {item.price?.toFixed(2) || "0.00"}</td>
                    <td> {item.is_visible ? <MDBBadge color='success' light>VISIBLE</MDBBadge> : <MDBBadge color='warning' light>HIDDEN</MDBBadge>}</td>
                    <td>
                      <MDBBtn
                        color="secondary"
                        outline
                        size="sm"
                        style={{ marginRight: "8px" }}
                        onClick={() => handleEditItemClick(item)}
                      >
                        Edit
                      </MDBBtn>
                      {item.is_visible ? <MDBBtn
                        color="warning"
                        outline
                        size="sm"
                        style={{ marginRight: "8px" }}
                        onClick={() => setModalData({
                          title: "Hide Item",
                          message: "Do you want to hide this item?",
                          action: handleVisibility,
                          actionData: item,
                          visibility: false
                        }) || setShowConfirm(true)}
                      >
                        HIDE
                      </MDBBtn> :
                        <MDBBtn
                          color="primary"
                          outline
                          size="sm"
                          style={{ marginRight: "8px" }}
                          onClick={() => setModalData({
                            title: "Un Hide Item",
                            message: "Do you want to unhide this item?",
                            action: handleVisibility,
                            actionData: item,
                            visibility: true
                          }) || setShowConfirm(true)}
                        >
                          UNHIDE
                        </MDBBtn>}

                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          )}
          <ConfirmModal
            show={showConfirm}
            onHide={() => setShowConfirm(false)}
            title={modalData.title}
            message={modalData.message}
            action={modalData.action}
            actionData={modalData.actionData}
            visibility={modalData.visibility}
          />

        </MDBContainer>
      </div>
    </>
  );
}

export default Items;
